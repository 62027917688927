import gsap from 'gsap'

function horizontalLoop(items, config) {
  items = gsap.utils.toArray(items)
  config = config || {}
  let tl = gsap.timeline({repeat: config.repeat, paused: config.paused, defaults: {ease: 'none'}, onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)}),
    length = items.length,
    startX = items[0].offsetLeft,
    times = [],
    widths = [],
    xPercents = [],
    curIndex = 0,
    pixelsPerSecond = (config.speed || 1) * 100,
    snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1),
    totalWidth, curX, distanceToStart, distanceToLoop, item, i
  gsap.set(items, {
    xPercent: (i, el) => {
      let w = widths[i] = parseFloat(gsap.getProperty(el, 'width', 'px'))
      xPercents[i] = snap(parseFloat(gsap.getProperty(el, 'x', 'px')) / w * 100 + gsap.getProperty(el, 'xPercent'))
      return xPercents[i]
    }
  })
  gsap.set(items, {x: 0})
  totalWidth = items[length-1].offsetLeft + xPercents[length-1] / 100 * widths[length-1] - startX + items[length-1].offsetWidth * gsap.getProperty(items[length-1], 'scaleX') + (parseFloat(config.paddingRight) || 0)
  for (i = 0; i < length; i++) {
    item = items[i]
    curX = xPercents[i] / 100 * widths[i]
    distanceToStart = item.offsetLeft + curX - startX
    distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, 'scaleX')
    tl.to(item, {xPercent: snap((curX - distanceToLoop) / widths[i] * 100), duration: distanceToLoop / pixelsPerSecond}, 0)
      .fromTo(item, {xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100)}, {xPercent: xPercents[i], duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond, immediateRender: false}, distanceToLoop / pixelsPerSecond)
      .add('label' + i, distanceToStart / pixelsPerSecond)
    times[i] = distanceToStart / pixelsPerSecond
  }
  function toIndex(index, vars) {
    vars = vars || {};
    (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length)
    let newIndex = gsap.utils.wrap(0, length, index),
      time = times[newIndex]
    if (time > tl.time() !== index > curIndex) {
      vars.modifiers = {time: gsap.utils.wrap(0, tl.duration())}
      time += tl.duration() * (index > curIndex ? 1 : -1)
    }
    curIndex = newIndex
    vars.overwrite = true
    return tl.tweenTo(time, vars)
  }
  tl.next = vars => toIndex(curIndex+1, vars)
  tl.previous = vars => toIndex(curIndex-1, vars)
  tl.current = () => curIndex
  tl.toIndex = (index, vars) => toIndex(index, vars)
  tl.times = times
  tl.progress(1, true).progress(0, true)
  if (config.reversed) {
    tl.vars.onReverseComplete()
    tl.reverse()
  }
  return tl
}

addEventListener('turbo:load', () => {
  const gallerySlider = document.getElementById('gallery_slider_feature')

  if(gallerySlider){
    (() => {
      const tops = gsap.utils.toArray('.top')
      const bottoms = gsap.utils.toArray('.bottom')

      horizontalLoop(tops, {paused: false, repeat: -1, speed: 0.5})
      horizontalLoop(bottoms, {paused: false, repeat: -1, reversed: true, speed: 0.5})
    })()
  }
})








/* // content- replace img src as desired

<style>
.wrapper,
.bottom-wrapper {
  align-items: center;
  display: flex;
  height: 20%;
  overflow: hidden;
  position: relative;
  justify-content: space-between;
}

.bottom-wrapper {
  margin-bottom: 1.04em;
}

.top,
.bottom {
  align-items: center;
  border-radius: 22px;
  display: flex;
  height: 80%;
  padding: 5px 5px;
  position: relative;
  width: 24%;
}

@media screen and (max-width: 1199px) {
  .top,
  .bottom {
    width: 80%;
  }
}
</style>

<div>
  <div id="gallery_slider_feature" class="wrapper section gallery_slider_feature">
    <img src="https://assets.quintevents.com/m/60c5f97e23455fd6/72_DPI_WEB-NBA-Experiences-NBA-Paris-Game-2023-After-Party-18.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/738a90fcda62a939/72_DPI_WEB-NBA-Experiences-NBA-Paris-Game-2023-Welcome-Reception.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/547d6ef447846f11/72_DPI_WEB-NBA-Experiences-NBA-Abu-Dhabi-2022-NBA-EXP-Lounge-22.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/3b6f7ceaf6c17491/72_DPI_WEB-NBA-Experiences-NBA-Abu-Dhabi-2022-Legend-Dinner-126.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/40b278ed1c01c900/72_DPI_WEB-NBAE-Draft-2022-EXP-Lounge-Appearances-12.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/60c5f97e23455fd6/72_DPI_WEB-NBA-Experiences-NBA-Paris-Game-2023-After-Party-18.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/738a90fcda62a939/72_DPI_WEB-NBA-Experiences-NBA-Paris-Game-2023-Welcome-Reception.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/547d6ef447846f11/72_DPI_WEB-NBA-Experiences-NBA-Abu-Dhabi-2022-NBA-EXP-Lounge-22.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="top gallery_slider_image">
    <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="top gallery_slider_image">
  </div>
  <div>
    <div id="gallery_slider_feature" class="bottom-wrapper section gallery_slider_feature">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
      <img src="https://assets.quintevents.com/m/5a76677066c55c3c/72_DPI_WEB-NBA-Experiences-NBA-Summer-League-2022-Las-Vegas-Legend-Dinner-074.jpg" class="bottom gallery_slider_image">
    </div>
  </div>
</div>
*/







