$(document)
  .on('click', '.package-container-wrapper .package-container .btn--view-more', function(event) {
    event.preventDefault()
    if(event.target.nodeName == 'ION-ICON' || event.target.nodeName == 'BUTTON'){
      $(event.target).closest('.blue-box-original-content').addClass('hidden')
      $(event.target).closest('.blue-box--bottom').find('.blue-box--content').addClass('show-content')    }
    return false
  })

  .on('click', '.package-container-wrapper .package-container .blue-box--content .btn--close', function(event) {
    event.preventDefault()
    $(event.target).closest('.blue-box--bottom').find('.blue-box-original-content').removeClass('hidden')
    $(event.target).parent().removeClass('show-content')
  })

  .on('click', '.package-container-wrapper .package-container .blue-box--content', function(event) {
    if(event.target.className === 'btn btn-primary' ) { return }
    event.preventDefault()
  })
