addEventListener('turbo:load', () => {
  const readMoreBtn = $('.inclusion-wrapper .load-more.toggle-btn')
  readMoreBtn.on('click', (e) => {
    e.preventDefault()
    let singleReadMore = e.target.closest('.toggle-btn')
    let closestParent = singleReadMore.closest('.inclusion-description-wrapper')
    closestParent.querySelector('.read-more-content').classList.toggle('active')
    closestParent.classList.toggle('active')

    if (closestParent.classList.contains('active')) {
      const hideText = singleReadMore.dataset.hideText
      singleReadMore.innerHTML = hideText + '<span class="arrow-wrapper"></span>'
    } else {
      const showText = singleReadMore.dataset.showText
      singleReadMore.innerHTML = showText + '<span class="arrow-wrapper"></span>'
    }
  })
})
