/* eslint-disable */
gsap.registerPlugin(ScrollTrigger)

document.addEventListener('turbo:load', () => {
  if(document.getElementById('item1--image'))
    gsap.to('.carousel-item--image-wrapper', {
      x: 650,
      scrollTrigger: {
        trigger: '.item1--image',
        scrub: true,
        toggleActions: 'play none none none'
      }
    })
})