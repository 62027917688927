$(document)
  .on('turbo:load', () => {
    let urlParams = window.location.search.slice(1)
    let url = new URLSearchParams(urlParams)

    $('.experience-wrapper .header-wrapper .reset').on('click', () => {
      history.replaceState('null', 'null', '?')
      $('.experience-wrapper .sort select').val('')
    })
    if (['htol', 'ltoh'].includes(urlParams)) {
      $('.experience-wrapper .sort select').val(urlParams)
    }
    $('.experience-wrapper .sort select').on('change', (e) => {
      url.set('', e.target.value) || history.replaceState('null', 'null', '?' + e.target.value)
    })
  })