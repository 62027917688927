import {sizeNavbar} from '../../shared/nav/navbar_functions'

addEventListener('turbo:load', () => {
  const isMobileNav = () => $('.check-for-mobile').css('float') === 'none'
  // const isTablet = () => window.outerWidth > 767 && window.outerWidth < 1200

  document.querySelectorAll('.dropdown').forEach(elem => {
    elem.addEventListener('show.bs.dropdown', function(event) {
      if(isMobileNav()) {
        $(event.currentTarget).siblings().addClass('hide')
        $(event.currentTarget).parent().siblings().addClass('hide')
        $(event.target).parent().addClass('open')
      }
    })

    elem.addEventListener('hidden.bs.dropdown', function(event) {
      if(isMobileNav()) {
        $(event.currentTarget).siblings().removeClass('hide')
        $(event.currentTarget).parent().siblings().removeClass('hide')
        $(event.target).parent().removeClass('open')
        $(event.target).removeClass('show')
        $('.navbar-collpase .dropdown-menu').removeClass('show')

        event.stopPropagation()
        event.preventDefault()
      }
    })
  })

  document.querySelector('.navbar-toggler').addEventListener('click', function() {
    $('body').toggleClass('overflow')
  })

  window.addEventListener('scroll', function() {
    $('body').removeClass('navbar-collapsed')

    if (window.pageYOffset == 0) {
      $('.navbar').removeClass('scrolled')
      $('body').removeClass('scrolled')
    } else {
      $('.navbar').addClass('scrolled')
      $('body').addClass('scrolled')
    }
  })

  window.addEventListener('resize', sizeNavbar)

})
