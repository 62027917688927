const gsap = window.gsap
const ScrollTrigger = window.ScrollTrigger

addEventListener('turbo:load', () => {
  if(document.querySelector('.banner-animation-wrapper')) {
    let bannerContainer = $('.banner-animation-wrapper')
    let bannerText = $('.banner-animation-text')
    let bannerShader = $('<div class="banner-animation-shader"></div>')

    bannerContainer.append(bannerShader)
    if (bannerShader.css('backgroundColor') == 'rgba(0, 0, 0, 0)') {
      bannerShader.css('backgroundColor', '#000000')
    }

    gsap.registerPlugin(ScrollTrigger)
    
    gsap.set(bannerText, {opacity: 0})

    let timeline = gsap.timeline({
      scrollTrigger: {
        trigger: bannerContainer,
        pin: true,
        start: '0% 8%',
        end: 'bottom 15%',
        scrub: 1.2
      }
    })

    timeline.from(bannerText, {
      y: 150,
      duration: .02
    }, '<')

    timeline.to(bannerText, {
      opacity: 1,
      duration: .02
    }, '<')

    timeline.fromTo(bannerContainer, {
      boxShadow:'inset 0px 0 0 7.5vw #041624',
      duration:.01,
      ease: 'Expo.easeOut'
    }, {
      boxShadow:'inset 0px 0px 0px 0px #041624',
      duration:.01,
      ease: 'Expo.easeIn'
    }, '< =+ 3')

    if (bannerShader.css('opacity') == '0') {
      timeline.to(bannerShader, {
        opacity: .6,
        duration: .05
      }, '<')
    } else {
      timeline.from(bannerShader, {
        opacity: 0,
        duration: .05
      }, '<')
    }
  }
})
